<template>
  <div>
    <formlist
      :title="title"
      :list="form_list"
      :showform="showForm"
      :listheaders="listheaders"
      :key="frml"
      :imp_excel="true"
      :btn_save="true"
      :btn_save_dis="btn_save_dis"
      :width="width"
      @excel_import="excel_si"
      @close="close_form"
      @save="save_import"
      :Total="true"
      :master="true"
      :alert="alert"
      :type_alert="type_alert"
      :message="message"
      :orientation="orientation"
    >
    </formlist>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
export default {
  name: "ImportData",
  components: {
    formlist: () => import("../components/FormList.vue"),
  },
  props: {
    showForm: Boolean,
    title: String,
    listheaders: Array,
    width: { type: String, default: "1000px" },
    items: Array,
    init_list: Array,
    item_key: String,
    col_key: String,
    item_id: String,
    alert: Boolean,
    type_alert: { type: String, default: "info" },
    message: String,
  },
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",

    orientation: "p",
    btn_save_dis: true,
    frml: 600,
    form_list: [],
  }),
  watch: {},

  computed: {},
  created() {
    if (this.init_list) this.form_list = this.init_list;
  },
  methods: {
    close_form() {
      this.$emit("close");
    },
    async excel_si(items) {
      // let f = items[0];

      let ok = true;

      if (
        arraysEqual(
          items.headers,
          this.listheaders.map((elm) => elm.text)
        ) == false
      ) {
        this.snackbar_text = "file format error!";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }

      if (ok) {
        let list = [];
        let id = -1;

        for (let index = 0; index < items.results.length; index++) {
          const element = items.results[index];
          let elm = {};
          let i = this.items.findIndex(
            (elm) => elm[this.item_key] == element[this.col_key]
          );

          if (i >= 0) {
            elm[this.item_id] = this.items[i][this.item_id];
            elm.id = this.items[i].id;
            //elm = this.items[i];
          } else {
            elm.id = id;
            id--;
          }
          this.listheaders.forEach((elm2) => {
            elm[elm2.value] = element[elm2.text];
          });
          list.push(elm);
        }
        if (list.length > 0) {
          this.btn_save_dis = false;
          this.form_list = list;
          this.frml++;
        }
      }
    },
    async save_import() {
      this.$emit("save_import", this.form_list);
    },
  },
};
</script>
